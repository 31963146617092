import React from 'react';
import styles from '../ServiceModal/Modal.module.css';
import downArrow from '../../../assets/images/downArrow.svg';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import arrow from '../../../assets/images/arrow.svg';

const Modal = ({ ...props }) => {
    const {t} = props;

    const clickHandle= ()=>{
        //Remove blur background and modal
        document.getElementById('book').style.filter="blur(0px)";
        document.getElementById('modal').style.display="none";
    }
    return (
        <div id = "modal" className={styles.mainContainer}>
            <div className={styles.subContainer}>
                <p className={styles.head}>{parse(t("What happens next"))}</p>
                <div className={styles.steps}>
                    <p >{parse(t("Book a general practioner"))}</p>
                    <img className={styles.dwnArrow} alt="arrow" src={downArrow}></img>
                    <p >{parse(t(`Your general practioner then recommends you`))}&nbsp;{props.title}</p>
                    <img className={styles.dwnArrow} alt="arrow" src={downArrow}></img>
                    <p>{parse(t(`Talk to your ${props.title} on your booked slot!`))}</p>
                </div>
                <div className={styles.cardBtn} onClick={clickHandle}>
                        <p>{parse(t("Book a general practioner"))}</p>
                        <img src={arrow} alt="arrow" className={styles.btnArrow}></img>
                    </div>
            </div>
        </div>
    )
}
export default withTranslation()(Modal);
